// // Import required libraries
import List from 'list.js'

import css from './styles.css';

let fbsTeams = [
    {fullname: "Air Force Falcons", shortname: "Air Force"},
    {fullname: "Akron Zips", shortname: "Akron"},
    {fullname: "Alabama Crimson Tide", shortname: "Alabama"},
    {fullname: "UAB Blazers", shortname: "UAB"},
    {fullname: "Appalachian State Mountaineers", shortname: "Appalachian State"},
    {fullname: "Arizona Wildcats", shortname: "Arizona"},
    {fullname: "Arizona State Sun Devils", shortname: "Arizona State"},
    {fullname: "Arkansas Razorbacks", shortname: "Arkansas"},
    {fullname: "Arkansas State Red Wolves", shortname: "Arkansas State"},
    {fullname: "Army Black Knights", shortname: "Army"},
    {fullname: "Auburn Tigers", shortname: "Auburn"},
    {fullname: "Ball State Cardinals", shortname: "Ball State"},
    {fullname: "Baylor Bears", shortname: "Baylor Bears"},
    {fullname: "Boise State Broncos", shortname: "Boise State"},
    {fullname: "Boston College Eagles", shortname: "Boston College"},
    {fullname: "Bowling Green Falcons", shortname: "Bowling Green"},
    {fullname: "Buffalo Bulls", shortname: "Buffalo"},
    {fullname: "BYU Cougars", shortname: "BYU"},
    {fullname: "California Golden Bears", shortname: "Cal"},
    {fullname: "UCLA Bruins", shortname: "UCLA"},
    {fullname: "UCF Knights", shortname: "UCF"},
    {fullname: "Central Michigan Chippewas", shortname: "Central Michigan"},
    {fullname: "Charlotte 49ers", shortname: "Charlotte"},
    {fullname: "Cincinnati Bearcats", shortname: "Cincinnati"},
    {fullname: "Clemson Tigers", shortname: "Clemson"},
    {fullname: "Coastal Carolina Chanticleers", shortname: "Coastal Carolina"},
    {fullname: "Colorado Buffaloes", shortname: "Colorado"},
    {fullname: "Colorado State Rams", shortname: "Colorado State"},
    {fullname: "Connecticut Huskies", shortname: "Connecticut"},
    {fullname: "Duke Blue Devils", shortname: "Duke"},
    {fullname: "Eastern Michigan Eagles", shortname: "Eastern Michigan"},
    {fullname: "East Carolina Pirates", shortname: "East Carolina"},
    {fullname: "FIU Panthers", shortname: "FIU"},
    {fullname: "Florida Gators", shortname: "Florida"},
    {fullname: "Florida Atlantic Owls", shortname: "Florida Atlantic"},
    {fullname: "Florida State Seminoles", shortname: "Florida State"},
    {fullname: "Fresno State Bulldogs", shortname: "Fresno State"},
    {fullname: "Georgia Bulldogs", shortname: "Georgia"},
    {fullname: "Georgia Southern Eagles", shortname: "Georgia Southern"},
    {fullname: "Georgia State Panthers", shortname: "Georgia State"},
    {fullname: "Georgia Tech Yellow Jackets", shortname: "Georgia Tech"},
    {fullname: "Hawai’i Rainbow Warriors", shortname: "Hawai’i"},
    {fullname: "Houston Cougars", shortname: "Houston"},
    {fullname: "Illinois Fighting Illini", shortname: "Illinois"},
    {fullname: "Indiana Hoosiers", shortname: "Indiana"},
    {fullname: "Iowa Hawkeyes", shortname: "Iowa"},
    {fullname: "Iowa State Cyclones", shortname: "Iowa State"},
    {fullname: "Kansas Jayhawks", shortname: "Kansas"},
    {fullname: "Kansas State Wildcats", shortname: "Kansas State"},
    {fullname: "Kent State Golden Flashes", shortname: "Kent State"},
    {fullname: "Kentucky Wilcats", shortname: "Kentucky"},
    {fullname: "LSU Tigers", shortname: "LSU"},
    {fullname: "Louisiana Tech Bulldogs", shortname: "Louisiana Tech"},
    {fullname: "Louisiana-Lafayette Ragin’ Cajuns", shortname: "Louisiana-Lafayette"},
    {fullname: "Louisiana-Monroe Warhawks", shortname: "Louisiana-Monroe"},
    {fullname: "Louisville Cardinals", shortname: "Louisville"},
    {fullname: "Marshall Thundering Herd", shortname: "Marshall"},
    {fullname: "Maryland Terrapins", shortname: "Maryland"},
    {fullname: "Massachusetts Minutemen", shortname: "Massachusetts"},
    {fullname: "Memphis Tigers", shortname: "Memphis"},
    {fullname: "Miami Hurricanes", shortname: "Miami"},
    {fullname: "Miami (OH) RedHawks", shortname: "Miami (OH)"},
    {fullname: "Michigan Wolverines", shortname: "Michigan"},
    {fullname: "Michigan State Spartans", shortname: "Michigan State"},
    {fullname: "Middle Tennessee Blue Raiders", shortname: "Middle Tennessee"},
    {fullname: "Minnesota Golden Gophers", shortname: "Minnesota"},
    {fullname: "Ole Miss Rebels", shortname: "Ole Miss"},
    {fullname: "Mississippi State Bulldogs", shortname: "Mississippi State"},
    {fullname: "Missouri Tigers", shortname: "Missouri"},
    {fullname: "Navy Midshipmen", shortname: "Navy"},
    {fullname: "Nebraska Cornhuskers", shortname: "Nebraska"},
    {fullname: "Nevada Wolf Pack", shortname: "Nevada"},
    {fullname: "UNLV Rebels", shortname: "UNLV"},
    {fullname: "New Mexico Lobos", shortname: "New Mexico"},
    {fullname: "New Mexico State Aggies", shortname: "New Mexico State"},
    {fullname: "North Carolina Tar Heels", shortname: "UNC"},
    {fullname: "NC State Wolfpack", shortname: "NC State"},
    {fullname: "North Texas Mean Green", shortname: "North Texas"},
    {fullname: "NIU Huskies", shortname: "NIU"},
    {fullname: "Northwestern Wildcats", shortname: "Northwestern"},
    {fullname: "Notre Dame Fighting Irish", shortname: "Notre Dame"},
    {fullname: "Ohio Bobcats", shortname: "Ohio"},
    {fullname: "Ohio State Buckeyes", shortname: "Ohio State"},
    {fullname: "Oklahoma Sooners", shortname: "Oklahoma"},
    {fullname: "Oklahoma State Cowboys", shortname: "Oklahoma State"},
    {fullname: "Old Dominion Monarchs", shortname: "Old Dominion"},
    {fullname: "Oregon Ducks", shortname: "Oregon"},
    {fullname: "Oregon State Beavers", shortname: "Oregon State"},
    {fullname: "Penn State Nittany Lions", shortname: "Penn State"},
    {fullname: "Pittsburgh Panthers", shortname: "Pitt"},
    {fullname: "Purdue Boilermakers", shortname: "Purdue"},
    {fullname: "Rice Owls", shortname: "Rice"},
    {fullname: "Rutgers Scarlet Knights", shortname: "Rutgers"},
    {fullname: "San Diego State Aztects", shortname: "San Diego State"},
    {fullname: "San Jose State Spartans", shortname: "San Jose State"},
    {fullname: "South Alabama Jaguars", shortname: "South Alabama"},
    {fullname: "South Carolina Gamecocks", shortname: "South Carolina"},
    {fullname: "South Florida Bulls", shortname: "South Florida"},
    {fullname: "USC Trojans", shortname: "USC"},
    {fullname: "SMU Mustangs", shortname: "SMU"},
    {fullname: "Southern Miss Golden Eagles", shortname: "Southern Miss"},
    {fullname: "Stanford Cardinal", shortname: "Standford"},
    {fullname: "Syracuse Orange", shortname: "Syracuse"},
    {fullname: "TCU Horned Frogs", shortname: "TCU"},
    {fullname: "Temple Owls", shortname: "Temple"},
    {fullname: "Tennessee Volunteers", shortname: "Tennessee"},
    {fullname: "Texas Longhorns", shortname: "Texas"},
    {fullname: "Texas A&M Aggies", shortname: "Texas A&M"},
    {fullname: "Texas State Bobcats", shortname: "Texas State"},
    {fullname: "Texas Tech Red Raiders", shortname: "Texas Tech"},
    {fullname: "UTEP Miners", shortname: "UTEP"},
    {fullname: "UTSA Roadrunners", shortname: "UTSA"},
    {fullname: "Toledo Rockets", shortname: "Toledo"},
    {fullname: "Troy Trojans", shortname: "Troy"},
    {fullname: "Tulane Green Wave", shortname: "Tulane"},
    {fullname: "Tulsa Golden Hurricane", shortname: "Tulsa"},
    {fullname: "Utah Utes", shortname: "Utah"},
    {fullname: "Utah State Aggies", shortname: "Utah State"},
    {fullname: "Vanderbilt Commodores", shortname: "Vanderbilt"},
    {fullname: "Virginia Cavaliers", shortname: "Virginia"},
    {fullname: "Virginia Tech Hokies", shortname: "Virginia Tech"},
    {fullname: "Wake Forest Demon Deacons", shortname: "Wake Forest"},
    {fullname: "Washington Huskies", shortname: "Washington"},
    {fullname: "Washington State Cougars", shortname: "Washington State"},
    {fullname: "West Virginia Mountaineers", shortname: "West Virginia"},
    {fullname: "Western Kentucky Hilltoppers", shortname: "Western Kentucky"},
    {fullname: "Western Michigan Broncos", shortname: "Western Michigan"},
    {fullname: "Wisconsin Badgers", shortname: "Wisconson"},
    {fullname: "Wyoming Cowboys", shortname: "Wyoming"}
];

const kMaxWeek = 16;
let gameDisplayList = null;
let displayWeek = -1;
let currentWeek = -1;
let calendarData, gameData, rankingData, bettingData, teamData, playsData;

let viewFilterControl = null;
// const axios = require('axios');

// call & parse both API endpoints
const urls = [
    // apiEndpoint + "/events?" + apiKey,
    // apiEndpoint + "/odds?regions=us&markets=spreads&oddsFormat=american&bookmakers=draftkings&" + apiKey,


    // "https://w2w.dave-hill.workers.dev/api/data/events/",
    // "https://w2w.dave-hill.workers.dev/api/data/odds/",


    "./eventData.json",
    "./spreadData.json"


]

const cfbApiKey = '/khF67ocBsAsl0aTOO/4xh2p+zpjHp/g3NuVdapNZPbAUUDEjiOhVDFmEfSPJqJF';

const proxyRequestHeaders = new Headers();
proxyRequestHeaders.append("Authorization", `Bearer ${cfbApiKey}`);
proxyRequestHeaders.append("Content-Type", "application/json");



let cfbApiUrl = 'https://api.collegefootballdata.com/games?year=2024&seasonType=regular&week=5';
cfbApiUrl = 'https://api.collegefootballdata.com/games?year=2024&week=5&division=fbs';
cfbApiUrl = 'https://api.collegefootballdata.com/lines?year=2024&week=5'; // Betting odds
// cfbApiUrl = 'https://api.collegefootballdata.com/calendar?year=2024'; // Weekly calendar rnages
cfbApiUrl = 'https://api.collegefootballdata.com/rankings?year=2024&week=4';

function getProxiedRequestPromise(urlString)
{
    const proxyURL = 'https://hidden-sun-6344.dave-hill.workers.dev/corsproxy/?apiurl=';

    let proxiedUrl = proxyURL + urlString;
    let promise = fetch(proxiedUrl, {headers: proxyRequestHeaders}).then(response => {
            if (!response.ok) { // Check for fetch errors
                throw new Error(`Error fetching ${[proxiedUrl]}: ${response.status}`);
            }
            return response.json();
        });

    return promise;
}

function getLocalFile(urlString)
{
    let promise = fetch(urlString).then(response => {
        if (!response.ok) {
            throw new Error(`Error fetching ${urlString}: ${response.status}`);
        }
        return response.json();
    });

    return promise;
}

function computeNthQuarterScore(scoreline, n)
{
    let result = 0;
    for (let i = 0; i < n; i++)
    {
        result += scoreline[i];
    }
    return result;
}

// Get the calendar
// Figure out the week we're in (through the following Tuesday is the previous week, Wednesday onwards is the new week)
// Get the game data


// cfbApiUrl = apiEndpoint + "/events/?" + apiKey;


// {
//     headers: {
//         'Authorization': `Bearer ${cfbApiKey}`,
//         'Content-Type': 'application/json',
//     }
// };



// // const baseURL = 'https://fantasy.premierleague.com/api/';




// const response = await fetch(apiUrl, { headers });
// const data = await response.json();


// Promise.all(urls.map(url => fetch(url).then(
//     response => {
//         response.json()
//     })))
// .then(
/*
let promises = urls.map(url => fetch(url)
.then(response => {
    if (!response.ok) { // Check for fetch errors
        throw new Error(`Error fetching ${url}: ${response.status}`);
    }
    return response.json(); // Parse JSON
}));

if (true)
{

    console.log(myHeaders);

    let proxiedUrl = proxyURL + cfbApiUrl; //encodeURIComponent(cfbApiUrl);
    promises.push(fetch(proxiedUrl, {headers: myHeaders}).then(response => {
        if (!response.ok) { // Check for fetch errors
            throw new Error(`Error fetching ${[proxiedUrl]}: ${response.status}`);
        }
        return response.json();
    }));
}
*/


let promises = [];
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/calendar?year=2024')); // get calendar
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/games?year=2024'));
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/rankings?year=2024'));
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/lines?year=2024'));
promises.push(getLocalFile('./teamData.json'));

// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/teams/fbs?year=2024'));
// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/plays?year=2024&week=6'));


Promise.all(promises)
    .then(results => {



        // console.log('Results:', results);
        // results => {
            calendarData = results[0];
            gameData = results[1];
            rankingData = results[2];
            bettingData = results[3];
            teamData = results[4];
            // playsData = results[4];
        // const [calendarData, gameData, rankingData, bettingData, playsData] = results; //eventData, spreadData, cfbGames] = results;


        // console.log(JSON.stringify(playsData));

        // console.log(JSON.stringify(teamData));

        let today = new Date(Date.now());
        
        for (let i = 0; i < calendarData.length; i++)
        {
            let week = calendarData[i];
            let startDate = new Date(Date.parse(week.firstGameStart));
            startDate.setDate(startDate.getDate()-2);
            let endDate = new Date(Date.parse(week.lastGameStart));
            endDate.setDate(endDate.getDate()+2);

            if (startDate <= today && today <= endDate)
            {
                currentWeek = week.week;
                break;
            }
        }

        console.log(
            "week number = " + currentWeek
        );

        // currentWeek = 6;


        // updateListForWeek(currentWeek);
        displayWeek = currentWeek;
        onChangeWeek(0);


        

        // document.getElementById("rankbuttonid")

        // for (let value of values)
        // {
        //     hackerList.add(value)
        // }

        /*const gameTable = document.createElement('table');
        gameTable.innerHTML = `
        <tr>
            <th style="width:40%">Game</th>
            <th style="width:20%">Time</th>
            <th>Spread</th>
        </tr>`;
        gameListDiv.append(gameTable);

        for (game of games)
        {
            // break;
            // date.toLocaleString('default', { month: 'long' }) + " " + date.getDate().toString() + " " + date.getHours() + ":" + date.getMinutes();

            let dateString = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(game.date);
            const gameElement = document.createElement('tr');
            // gameElement.className = game.spread < 10 ? "tr_good" : "tr";
            let gameString = 
                ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team + " @ " + 
                ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team;

            // console.log(gameString);

            gameElement.innerHTML = `
            <td>${gameString}</td>
            <td>${dateString}</td>
            <td>${game.spread}</td>
            `;
            gameTable.appendChild(gameElement);   
        }
        */

            // create HTML
            
            // console.log(entry);
    })
    .catch(error => console.error('Error:', error));




    if (false)
    {
        document.getElementById("firebuttonid").onclick = () => {
            gameDisplayList.search("\u{1F525}")
        }

        document.getElementById("rankbuttonid").onclick = () => {

            gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (gameitem.away_rank <= 25 || gameitem.home_rank <= 25)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
        }

        document.getElementById("futurebuttonid").onclick = () => {

            gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (!gameitem.completed && !gameitem.skip)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
        }

        document.getElementById("allbuttonid").onclick = () => {
            gameDisplayList.search('');
        }
        // document.onload = () => {
            document.getElementById('prevweekbuttonid').onclick = () => {onChangeWeek( -1 )};
            document.getElementById('nextweekbuttonid').onclick = () => {onChangeWeek( 1 )};
        // }
    }
    else
    {
        if (viewFilterControl == null)
            viewFilterControl = document.getElementById("view_filter");

        viewFilterControl.onchange = (_event) => {
            // console.log(_item);
            viewFilterChanged();
        };

        document.getElementById('prevweekbuttonid').onclick = () => {onChangeWeek( -1 )};
        document.getElementById('nextweekbuttonid').onclick = () => {onChangeWeek( 1 )};
    }
    


// function firebutton()
// {
//     let textfield = getElementById("search_box");
//     textfield.setAttribute('value', "\u{1F525}");
// }

function onChangeWeek(increment)
{
    displayWeek = displayWeek + increment;
    let prevButton = document.getElementById('prevweekbuttonid');
    let nextButton = document.getElementById('nextweekbuttonid');

    if (displayWeek <= 1)
    {
        displayWeek = 1;
        prevButton.disabled = true;
    }
    else if (displayWeek >= kMaxWeek)
    {
        displayWeek = kMaxWeek;
        nextButton.disabled = true;
    }
    else
    {
        nextButton.disabled = false;
        prevButton.disabled = false;
    }

    document.getElementById('weeklabelid').innerHTML = "Week " + displayWeek;
    updateListForWeek(displayWeek);

    viewFilterChanged(); // re-apply the view filter based on the current value
}


function updateListForWeek(_week)
{
    displayWeek = _week;

    let filteredGames = gameData.filter((game) => (game.week == displayWeek && (game.away_division === "fbs" || game.home_division === "fbs")));

    let filteredRanking = rankingData.find((entry) => (entry.week == displayWeek));
    // let filteredPoll = rankingData.filter((entry) => (entry.week == currentWeek));
    let filteredBettingData = bettingData.filter((entry) => (entry.week == displayWeek));

    // console.log(JSON.stringify(cfbGames)); //.toString());

    // filter data to the week and conference we want
    // let cfbGamesFiltered = cfbGames.filter((game) => (game.week == 5 && (game.away_division === "fbs" || game.home_division === "fbs")));




    const gameListDiv = document.getElementById('game-list');

    let games = [];
    let rankingPoll = null;
    if (filteredRanking && filteredRanking.polls)
    {
        rankingPoll = filteredRanking.polls.find((entry) => (entry.poll == "AP Top 25"));
    }

    filteredGames.sort((a,b) => {
        return a.date - b.date;
    });

    let game;
    for (game of filteredGames) {

        let date = new Date(Date.parse(game.start_date));
        let gameEntry = {
            date: date,
            home_team: game.home_team,
            away_team: game.away_team,

        };

        if (rankingPoll)
        {
            gameEntry.home_rank = rankingPoll.ranks.find((entry) => (entry.school == game.home_team));
            if (gameEntry.home_rank != null)
                gameEntry.home_rank = gameEntry.home_rank.rank;
            else
                gameEntry.home_rank = 1000;

            gameEntry.away_rank = rankingPoll.ranks.find((entry) => (entry.school == game.away_team));
            if (gameEntry.away_rank != null)
                gameEntry.away_rank = gameEntry.away_rank.rank;
            else
                gameEntry.away_rank = 1000;
            
        }
        else
        {
            gameEntry.home_rank = 1000;
            gameEntry.away_rank = 1000;
        }






        let watchability;
        
        let skip = true;
        if (game.completed)
        {
            // console.log(game.away_team + " @ " + game.home_team);


            /*

            Scoring -- what makes it interesting?
            Lots of scoring on both sides
            Half-time adjustments -- winning team has a weak first half
            Lead changes -- look at this at the end of each quarter (as a start)
            4th quarter comeback


            What makes it boring?
            Not much scoring on either side
            Not much scoring late in the game



            Three star:
                Drama

            */


            /*
            New scoring idea

            assign a point for each of the following, then total up the points and convert to fires:
             - overtime
             - 4th quarter comeback
             - big 4th quarter comeback
             - halftime adjustments
             - big halftime adjustements
             - high scoring (on both sides)
             - close game
             - very close game
             - mayhem
             - big mayhem (unranked knocking off top 10 team, or ranking gap of at least N)
             - high excitement index
             - underdog wins (even if not mayhem)?
             - high number lead changes
             - high number of turnovers (or turnovers in 4th quarter)

            */

            let pointDifferential = Math.abs(game.home_points - game.away_points);
            let totalPoints = game.home_points + game.away_points;
            let endOfRegulationTotalPoints = computeNthQuarterScore(game.home_line_scores, 4) + computeNthQuarterScore(game.away_line_scores, 4);

            let watchForPoints = pointDifferential < 14;

            let excitementIndex = game.excitement_index ?? 0.0;

            // let watchForExcitementIndex = excitementIndex > ;
            
            // Check for mayhem
            let homeWin = game.home_points > game.away_points;
            let winnerRank = homeWin ? gameEntry.home_rank : gameEntry.away_rank; 
            let loserRank = homeWin ? gameEntry.away_rank : gameEntry.home_rank;
            
            let watchForMayhem = winnerRank > loserRank; // lower rank number == higher number, so if loser rank is greater than winner, it's mayhem

            let watchForOvertime = game.home_line_scores.length > 4;

            let watchFor4thQuarterComeback = false;
            let fourthQuarterComebackGap = 0;
            if (!watchForOvertime)
            {
                // check for a 4th quarter comeback
                let home3rdQuarterScore = computeNthQuarterScore(game.home_line_scores, 3);
                let away3rdQuarterScore = computeNthQuarterScore(game.away_line_scores, 3);
                let winnerThirdQuarterScore = homeWin ? home3rdQuarterScore : away3rdQuarterScore;
                let loserThirdQuarterScore = homeWin ? away3rdQuarterScore : home3rdQuarterScore;

                watchFor4thQuarterComeback = loserThirdQuarterScore > winnerThirdQuarterScore;

                if (watchFor4thQuarterComeback)
                    fourthQuarterComebackGap = (homeWin ? game.home_points : game.away_points) - winnerThirdQuarterScore;
            }
                
            watchability = "Skip";
            if (
                (endOfRegulationTotalPoints > 40 && watchForOvertime) //high-scoring + overtime
                || (watchForMayhem && (watchForOvertime || pointDifferential < 14))
                || (!watchForOvertime && endOfRegulationTotalPoints > 40 && excitementIndex > 7) // exciting, high scoring game
                || (pointDifferential < 4 && totalPoints > 60)) // close, high-scoring game
            {
                watchability = "\u{1F525}\u{1F525}\u{1F525}";
                skip = false;
            }
            else if ((watchFor4thQuarterComeback && fourthQuarterComebackGap > 16) || (pointDifferential < 10 && totalPoints > 60))
            {
                watchability = "\u{1F525}\u{1F525}";
                skip = false;
            }
            else if ((watchForPoints || excitementIndex > 6.5 || watchForMayhem || watchForOvertime || watchFor4thQuarterComeback))
            {
                watchability = "\u{1F525}";
                skip = false;
            }
            // watchability = (watchForPoints || watchForExcitementIndex || watchForMayhem 
            //     || watchForOvertime || watchFor4thQuarterComeback) ? "\u{1F525}" : "Skip";

                
            // console.log(game.away_team + ": " + game.away_points + " @ " + game.home_team + ": " + game.home_points );
        }
        else
        {
            // get the spread
            let gameBettingData = filteredBettingData.find((entry) => (entry.homeTeam == game.home_team));
            let spread = null;
            if (gameBettingData && gameBettingData.lines && gameBettingData.lines.length > 0)
            {
                spread = Math.abs(gameBettingData.lines[0].spread);
            }
            skip = (spread && spread >= 10);
            watchability = "No Data";
            if (spread)
            {
                watchability = (skip ? "Skip" : "Watch") + " (" + spread + ")";
            }
            
        }
        // get ranking

            // let dateString = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(date);

        gameEntry['spread'] = watchability;// ? "watch" : "skip";
        gameEntry['completed'] = game.completed;
        gameEntry['skip'] = skip;
        gameEntry['away_id'] = game.away_id;
        gameEntry['home_id'] = game.home_id;

        games.push(gameEntry);
    }

    // sort entries
    games.sort((a,b) => {
        
        if (a.date === b.date)
        {
            let val = a.spread - b.spread;
            return val;
        }
        return a.date - b.date;
    });

        // games = games.sort();
        // shuffleArray(games);
      
    

    var options = {
        item: 'game-list-item',
        valueNames: [
            {name:'away_team_logo', attr:'src'},
            'away_team_name', 
            {name:'home_team_logo', attr: 'src'}, 
            'home_team_name', 
            'time', 
            'watchability'
        ]
    };
    
    var values = [];
    
    // for (let i = 0; i < games.length; i++)
    for (game of games)
    {
        // let game = games[i];

        let dateString = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(game.date);
        // const gameElement = document.createElement('li');

        let gameString = 
            ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team + " @ " + 
            ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team;

        // game.away_team = "Air Force";
        let away_team = teamData.find((entry) => (entry.id == game.away_id));
        let home_team = teamData.find((entry) => (entry.id == game.home_id));

            // let result = entry.school.localeCompare(game.away_team);
        //     // return result == 0});
        // let home_team = teamData.find((entry) => {
        //     entry.school.localeCompare(game.home_team) == 0});

        let item = {
            away_team_logo: ("http://a.espncdn.com/i/teamlogos/ncaa/500/" + game.away_id.toString() + ".png"), 
            away_team_name: ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team,
            home_team_logo: ("http://a.espncdn.com/i/teamlogos/ncaa/500/" + game.home_id.toString() + ".png"),
            home_team_name: ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team,
            // game: gameString,
            time: dateString,
            watchability: game.spread,
            gameitem: game,
            mascot: (away_team ? away_team.mascot : "") + ", " + (home_team ? home_team.mascot : ""),
        };
        values.push(item);
    }


    if (gameDisplayList != null)
    {
        gameDisplayList.clear();
        gameDisplayList.add(values);
        gameDisplayList.search(''); //reset filer @TODO - persist/repapply filter?
    }
    else
    {
        gameDisplayList = new List('game-list-div', options, values);
    }
}

function viewFilterChanged()
{
    console.log(viewFilterControl.value);

    if (viewFilterControl.value == 'view_all_good_games')
    {
        gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (!gameitem.skip)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
    }
    else if (viewFilterControl.value == 'view_ranked_games')
    {
        gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (gameitem.away_rank <= 25 || gameitem.home_rank <= 25)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
    }
    else
    {
        gameDisplayList.search('');
    }

}